import { HttpExceptionDto, UserCreateDto, UserDto, UserLoginWithOtpRequestDto, UserRoleDto } from '@/castapi';
import router, { getHomePath } from '@/router';
import { EMPTY_USER_ROLE_NAME, USER_ROLE_IDS } from '@/shared/constants';
import { AppLogger } from '@/logger';
import { getErrorMessage, getUsersApi, isKnownError, omitUserSecretFields } from '@/castapi/helpers';
import { Route } from 'vue-router/types/router';
import { Dummy } from '@/shared/types/common';
import { IActionParams } from '@/store/modules/index';
import { RoleWithBindingDto } from '@/store/modules/organizations';
import snackbarPlugin from '@/plugins/snackbar';

const logger = new AppLogger('login state');

interface ILoginState {
  twofaSetupQrCodeDataUrl: string | null;
  loggedUser: UserDto | null;
  sendOtpToEmailInProgress: boolean;
  sendOtpToEmailError: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  loginError: string | null;
  loggingIn: boolean;
  resetPasswordInProgress: boolean;
  resetPasswordError: string | null;
  changingPassword: boolean;
  changePasswordError: string | null;
  tokenValid: boolean;
  userInvitationNotFound: boolean;
  invitedUser: UserDto | null;
  reloadUserDataInProgress: boolean;
  reloadUserDataError: string | null;
  prevRoute: Route | null;
}

const initialState = (): ILoginState => ({
  twofaSetupQrCodeDataUrl: null,
  loggedUser: null,
  sendOtpToEmailInProgress: false,
  sendOtpToEmailError: null,
  accessToken: null,
  refreshToken: null,
  loginError: null,
  loggingIn: false,
  resetPasswordInProgress: false,
  resetPasswordError: null,
  changingPassword: false,
  changePasswordError: null,
  tokenValid: false,
  userInvitationNotFound: false,
  invitedUser: null,
  reloadUserDataInProgress: false,
  reloadUserDataError: null,
  prevRoute: null,
});

type Getters = {
  userId: number;
  user: UserDto | null;
  accessToken: string;
  refreshToken: string;
  isAdmin: boolean;
  loggedIn: boolean;
};

type ActionParams = IActionParams<ILoginState, Getters>;

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SEND_OTP_TO_EMAIL_IN_PROGRESS(state: ILoginState): void {
      state.sendOtpToEmailInProgress = true;
      state.sendOtpToEmailError = null;
    },
    SEND_OTP_TO_EMAIL_SUCCESS(state: ILoginState): void {
      state.sendOtpToEmailInProgress = false;
      state.sendOtpToEmailError = null;
    },
    SEND_OTP_TO_EMAIL_ERROR(state: ILoginState, error: Error): void {
      state.sendOtpToEmailInProgress = false;
      state.sendOtpToEmailError = getErrorMessage(error);
    },
    PASSWORD_VERIFIED(state: ILoginState, data: { twofaSetupQrCodeDataUrl: string }): void {
      state.twofaSetupQrCodeDataUrl = data.twofaSetupQrCodeDataUrl;
      state.loggingIn = false;
    },
    OTP_SENT(state: ILoginState): void {
      state.loggingIn = false;
    },
    ACCESS_TOKEN_RECEIVED(
      state: ILoginState,
      { accessToken, refreshToken }: { accessToken: string; refreshToken: string },
    ): void {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    LOGIN_SUCCESS(state: ILoginState, user: UserDto): void {
      state.loggedUser = user;
      state.loggingIn = false;
      state.loginError = null;
    },
    LOGIN_IN_PROGRESS(state: ILoginState): void {
      state.loggingIn = true;
      state.loginError = null;
    },
    LOGIN_ERROR(state: ILoginState, payload: Error): void {
      state.loggingIn = false;
      state.loginError = getErrorMessage(payload);
    },
    CLEAR_LOGIN_ERROR(state: ILoginState): void {
      state.loginError = null;
    },
    RESET_PASSWORD_SUCCESS(state: ILoginState): void {
      state.resetPasswordInProgress = false;
      state.resetPasswordError = null;
    },
    RESET_PASSWORD_IN_PROGRESS(state: ILoginState): void {
      state.resetPasswordInProgress = true;
      state.resetPasswordError = null;
    },
    RESET_PASSWORD_ERROR(state: ILoginState, error: Error): void {
      state.resetPasswordInProgress = false;
      state.resetPasswordError = getErrorMessage(error);
    },
    CHANGING_PASSWORD(state: ILoginState): void {
      state.changingPassword = true;
      state.changePasswordError = null;
    },
    CHANGE_PASSWORD_SUCCESS(state: ILoginState): void {
      state.changingPassword = false;
      state.changePasswordError = null;
    },
    CHANGE_PASSWORD_ERROR(state: ILoginState, error: Error): void {
      state.changingPassword = false;
      state.changePasswordError = getErrorMessage(error);
    },
    RELOAD_USER_DATA(state: ILoginState): void {
      state.reloadUserDataInProgress = true;
      state.reloadUserDataError = null;
    },
    RELOAD_USER_DATA_SUCCESS(state: ILoginState, user: UserDto): void {
      state.loggedUser = user;
      state.reloadUserDataInProgress = false;
    },
    RELOAD_USER_DATA_ERROR(state: ILoginState, error: Error): void {
      state.reloadUserDataInProgress = false;
      state.reloadUserDataError = getErrorMessage(error);
    },
    RESET_STATE(state: ILoginState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
    UPDATE(state: ILoginState, user: UserDto): void {
      state.loggedUser = user;
    },
    TOKEN_VALID(state: ILoginState): void {
      state.loggingIn = false;
      state.loginError = null;
      state.tokenValid = true;
    },
    TOKEN_NOT_VALID(state: ILoginState): void {
      state.loggingIn = false;
      state.loginError = null;
      state.tokenValid = false;
    },
    USER_INVITATION_DATA_LOADED(state: ILoginState, data: UserDto): void {
      state.invitedUser = data;
      state.loggingIn = false;
    },
    // CLEAR_USER_INVITATION_DATA(state: ILoginState): void {
    //   state.invitedUser = null;
    // },
    SET_PREV_ROUTE(state: ILoginState, route: Route): void {
      state.prevRoute = route;
    },
  },
  actions: {
    async sendOtpToEmail({ commit }: ActionParams, email: string): Promise<void> {
      try {
        commit('SEND_OTP_TO_EMAIL_IN_PROGRESS');
        await getUsersApi().usersControllerSendOtpToEmail({ email });
        commit('SEND_OTP_TO_EMAIL_SUCCESS');
      } catch (error) {
        commit('SEND_OTP_TO_EMAIL_ERROR', error);
        logger.captureStoreError('sendOtpToEmail', error, { email });
      }
    },
    async LOGIN({ commit, dispatch }: ActionParams, user: UserLoginWithOtpRequestDto): Promise<void> {
      try {
        commit('SEND_OTP_TO_EMAIL_SUCCESS');
        commit('LOGIN_IN_PROGRESS');
        const response = await getUsersApi().usersControllerLogin(user);
        if (response.data?.accessToken === null) {
          commit('PASSWORD_VERIFIED', response.data);
          return;
        }
        commit('ACCESS_TOKEN_RECEIVED', response.data);
        await dispatch('GET_CURRENT_USER_DATA');
      } catch (error) {
        commit('LOGIN_ERROR', error);
        if (isKnownError(error as HttpExceptionDto, 400, 'Invalid email or password')) {
          return;
        }
        logger.captureStoreError('LOGIN', error, { userEmail: user.email });
      }
    },
    clearLoginError({ commit }: ActionParams): void {
      commit('CLEAR_LOGIN_ERROR');
    },
    async LOGOUT({ commit, dispatch, getters }: ActionParams): Promise<void> {
      const accessToken = getters.accessToken;
      const refreshToken = getters.refreshToken;
      if (accessToken || refreshToken) {
        await getUsersApi().usersControllerLogout({ accessToken, refreshToken });
      }
      commit('RESET_STATE');
      await dispatch('resetState', null, { root: true });
      await dispatch('adminOrganizations/resetState', null, { root: true });
      await dispatch('adminDiscounts/resetState', null, { root: true });
      await dispatch('adminDongles/resetState', null, { root: true });
      await dispatch('adminInventory/resetState', null, { root: true });
      await dispatch('adminOrders/resetState', null, { root: true });
      await dispatch('adminUploads/resetState', null, { root: true });
      await dispatch('adminReleases/resetState', null, { root: true });
      await dispatch('adminUsers/resetState', null, { root: true });
      await dispatch('adminDemos/resetState', null, { root: true });
      await dispatch('shop/resetState', null, { root: true });
      await dispatch('invoices/resetState', null, { root: true });
      await dispatch('demo/resetState', null, { root: true });
      await dispatch('dongles/resetState', null, { root: true });
      await dispatch('organizations/resetState', null, { root: true });
      await dispatch('dictionary/resetState', null, { root: true });
      await dispatch('snackbar/resetState', null, { root: true });
    },
    async GET_CURRENT_USER_DATA({ commit, dispatch, getters }: ActionParams): Promise<void> {
      const accessToken = getters.accessToken;
      if (!accessToken) {
        return;
      }
      try {
        const [response] = await Promise.all([
          getUsersApi(accessToken).usersControllerGetUser(),
          dispatch('organizations/getUserOrganizations', accessToken, { root: true }),
          dispatch('dictionary/getUserRoles', { accessToken }, { root: true }),
          dispatch('demo/loadDemos', accessToken, { root: true }),
        ]);
        commit('LOGIN_SUCCESS', response.data);
        if (!getters.isAdmin) {
          await dispatch('shop/getUserUnfinishedOrder', null, { root: true });
        }
        await dispatch('redirectLoggedUser');
      } catch (error) {
        commit('LOGIN_ERROR', error);
        logger.captureStoreError('GET_CURRENT_USER_DATA', error);
      }
    },
    async RELOAD_CURRENT_USER_DATA(
      { commit, dispatch, getters, rootGetters }: ActionParams,
      payload: string,
    ): Promise<void> {
      try {
        const accessToken = payload || getters.accessToken;
        // Note: need for legacy - detect that user role types outdated and reload them
        const roles = rootGetters['dictionary/userRoleTypes'];
        if (!roles.find((r: UserRoleDto) => r.userRoleId === USER_ROLE_IDS.VIRTUAL_UTILITIES_USER_ROLE_ID)) {
          await dispatch('dictionary/getUserRoles', { accessToken, force: true }, { root: true });
        }
        commit('RELOAD_USER_DATA');
        const [response] = await Promise.all([
          getUsersApi(accessToken).usersControllerGetUser(),
          dispatch('organizations/getUserOrganizations', accessToken, { root: true }),
          dispatch('demo/loadDemos', accessToken, { root: true }),
        ]);
        commit('RELOAD_USER_DATA_SUCCESS', response.data);
      } catch (error) {
        commit('RELOAD_USER_DATA_ERROR', error);
      }
    },
    async redirectLoggedUser(): Promise<void> {
      const redirect = router.currentRoute.query.redirect;
      const fullPath = redirect && redirect !== 'null' ? (redirect as string) : getHomePath();
      await router.push({ path: fullPath });
    },
    async REGISTER_USER({ commit, dispatch }: ActionParams, user: UserCreateDto): Promise<void> {
      try {
        commit('LOGIN_IN_PROGRESS');
        const response = await getUsersApi().usersControllerRegister(user);
        commit('ACCESS_TOKEN_RECEIVED', response.data);
        await dispatch('GET_CURRENT_USER_DATA');
        // await router.push({ name: 'loginsignin' });
      } catch (error) {
        commit('LOGIN_ERROR', error);
        if (isKnownError(error as HttpExceptionDto, 409, /Organization with email '.*' already exists/)) {
          return;
        }
        logger.captureStoreError('REGISTER_USER', error, omitUserSecretFields(user));
      }
    },
    updateUser({ commit }: ActionParams, user: UserDto): void {
      commit('UPDATE', user);
    },
    async resetPassword({ commit }: ActionParams, email: string): Promise<void> {
      try {
        commit('RESET_PASSWORD_IN_PROGRESS');
        await getUsersApi().usersControllerSendResetPasswordEmail(email);
        commit('RESET_PASSWORD_SUCCESS');
      } catch (error) {
        commit('RESET_PASSWORD_ERROR', error);
        logger.captureStoreError('resetPassword', error, { email });
      }
    },
    async checkPasswordResetToken({ commit }: ActionParams, token: string): Promise<void> {
      try {
        commit('LOGIN_IN_PROGRESS');
        const response = await getUsersApi().usersControllerCheckPasswordResetToken(token);
        if (response.data) {
          commit('TOKEN_VALID');
        } else {
          commit('TOKEN_NOT_VALID');
        }
      } catch (error) {
        commit('LOGIN_ERROR', error);
        logger.captureStoreError('checkPasswordResetToken', error);
      }
    },
    async setNewPassword(
      { commit }: ActionParams,
      { token, password }: { token: string; password: string },
    ): Promise<void> {
      commit('CHANGING_PASSWORD');
      try {
        await getUsersApi().usersControllerSetNewPassword({ token, password });
        commit('CHANGE_PASSWORD_SUCCESS');
        await router.push({ name: 'loginsignin', query: { passwordChanged: 'true' } });
      } catch (error) {
        commit('CHANGE_PASSWORD_ERROR', error);
        logger.captureStoreError('setNewPassword', error);
      }
    },
    async getUserByInvitation({ commit }: ActionParams, token: string): Promise<void> {
      commit('LOGIN_IN_PROGRESS');
      try {
        const response = await getUsersApi().usersControllerGetUserByInvitation({ token });
        commit('USER_INVITATION_DATA_LOADED', response.data);
      } catch (error) {
        await router.push({ path: getHomePath() });
        snackbarPlugin.error(getErrorMessage(error as Error), 20);
        commit('LOGIN_ERROR', error);
        logger.captureStoreError('getUserByInvitation', error);
      }
    },
    async join({ commit, dispatch, getters }: ActionParams, token: string): Promise<void> {
      commit('LOGIN_IN_PROGRESS');
      try {
        if (!getters.loggedIn || !getters.user?.active) {
          throw new Error('User data is required to use invitation');
        }
        await getUsersApi(getters.accessToken).usersControllerJoin({ token });
        // commit('CLEAR_USER_INVITATION_DATA');
        await dispatch('GET_CURRENT_USER_DATA');
        snackbarPlugin.success('You have successfully joined the organization', 20);
      } catch (error) {
        commit('LOGIN_ERROR', error);
        logger.captureStoreError('join', error);
      }
    },
    async completeRegistrationAndJoin(
      { commit, dispatch }: ActionParams,
      { token, user }: { token: string; user?: UserDto },
    ): Promise<void> {
      commit('LOGIN_IN_PROGRESS');
      try {
        if (!user) {
          throw new Error('User data is required to use invitation');
        }
        const response = await getUsersApi().usersControllerCompleteRegistrationAndJoin({ token, user });
        commit('ACCESS_TOKEN_RECEIVED', response.data);
        // commit('CLEAR_USER_INVITATION_DATA');
        await dispatch('GET_CURRENT_USER_DATA');
      } catch (error) {
        commit('LOGIN_ERROR', error);
        logger.captureStoreError('join', error);
      }
    },
    async refreshToken({ commit, getters }: ActionParams): Promise<void> {
      try {
        const refreshToken = getters.refreshToken;
        if (!refreshToken) {
          return;
        }
        const response = await getUsersApi().usersControllerRefreshToken({ refreshToken });
        commit('ACCESS_TOKEN_RECEIVED', response.data);
      } catch (error) {
        logger.captureStoreError('refreshToken', error);
      }
    },
    async changePassword(
      { commit, getters }: ActionParams,
      { password, oldPassword }: { password: string; oldPassword: string },
    ): Promise<void> {
      const accessToken = getters.accessToken;
      if (!accessToken) {
        return;
      }
      commit('CHANGING_PASSWORD');
      try {
        await getUsersApi(accessToken).usersControllerChangeUserPassword({
          newPassword: password,
          oldPassword,
        });
        commit('CHANGE_PASSWORD_SUCCESS');
      } catch (error) {
        commit('CHANGE_PASSWORD_ERROR', error);
        logger.captureStoreError('changePassword', error);
      }
    },
    setPrevRoute({ commit }: ActionParams, route: Route): void {
      commit('SET_PREV_ROUTE', route);
    },
  },
  getters: {
    twofaSetupQrCodeDataUrl: (state: ILoginState): string | null => state.twofaSetupQrCodeDataUrl,
    loggedIn: (_state: ILoginState, getters: Getters): boolean => Boolean(getters.userId),
    isAdmin: (_state: ILoginState, getters: Getters, _rootState: Dummy, rootGetters: Dummy): boolean =>
      getters.userId && rootGetters['organizations/isAdmin'](getters.userId),
    // roleId: (state: ILoginState, getters:Getters, rootState:Dummy, rootGetters:Dummy) =>
    //   getters.userId ? rootGetters['organizations/roleId'](getters.userId) || USER_ROLE_IDS.NOBODY_USER_ROLE_ID : null,
    roleName: (_state: ILoginState, getters: Getters, _rootState: Dummy, rootGetters: Dummy): string =>
      getters.userId ? rootGetters['organizations/roleName'](getters.userId) || EMPTY_USER_ROLE_NAME : null,
    isOwner: (state: ILoginState, _getters: Getters, _rootState: Dummy, rootGetters: Dummy): boolean =>
      rootGetters['organizations/isOwner'](state.loggedUser?.userId),
    isDemo: (state: ILoginState, _getters: Getters, _rootState: Dummy, rootGetters: Dummy): boolean =>
      rootGetters['organizations/isDemo'](state.loggedUser?.userId),
    isMember: (state: ILoginState, _getters: Getters, _rootState: Dummy, rootGetters: Dummy): boolean =>
      rootGetters['organizations/isMember'](state.loggedUser?.userId),
    isEmptyRole: (state: ILoginState, _getters: Getters, _rootState: Dummy, rootGetters: Dummy): boolean =>
      rootGetters['organizations/isEmptyRole'](state.loggedUser?.userId),
    activeUserMainRoleWithPermissions: (
      _state: ILoginState,
      getters: Getters,
      _rootState: Dummy,
      rootGetters: Dummy,
    ): UserRoleDto =>
      getters.userId ? rootGetters['organizations/activeUserMainRoleWithPermissions'](getters.userId) : null,
    user: (state: ILoginState): UserDto | null => state.loggedUser,
    userId: (state: ILoginState): number | undefined => state.loggedUser?.userId,
    sendOtpToEmailInProgress: (state: ILoginState): boolean => state.sendOtpToEmailInProgress,
    sendOtpToEmailError: (state: ILoginState): string | null => state.sendOtpToEmailError,
    accessToken: (state: ILoginState): string | null => state.accessToken,
    refreshToken: (state: ILoginState): string | null => state.refreshToken,
    loggingIn: (state: ILoginState): boolean => state.loggingIn,
    loginError: (state: ILoginState): string | null => state.loginError,
    resetPasswordInProgress: (state: ILoginState): boolean => state.resetPasswordInProgress,
    resetPasswordError: (state: ILoginState): string | null => state.resetPasswordError,
    changingPassword: (state: ILoginState): boolean => state.changingPassword,
    changePasswordError: (state: ILoginState): string | null => state.changePasswordError,
    invitedUser: (state: ILoginState): UserDto | null => state.invitedUser,
    userRoles: (_state: ILoginState, getters: Getters, _rootState: Dummy, rootGetters: Dummy): RoleWithBindingDto =>
      rootGetters['organizations/activeUserRoles'](getters.userId),
    prevRoute: (state: ILoginState): Route | null => state.prevRoute,
  },
};
